@import "../styles.module";
@import "@/styles/mixins";
.footer_cont {
  width: 100%;
  margin-top: 4rem;
  background: linear-gradient(145.78deg, #b18bfd 13.8%, #6846eb 93.2%);
  padding: 2rem 0;
  font-family: "Poppins", sans-serif;

  @include for-phone-only {
    margin-top: 4rem;
    display: flex;
    align-items: start;
    padding-bottom: 2rem;
  }
  .footer_content_cont {
    width: 100%;
    @include for-phone-only {
      @include setContentWidth;
      margin: auto;
    }
  }

  .footer_btn {
    height: 60px;
    font-size: 16px;
    max-width: 400px;

    @include for-xl-only {
      font-size: 13px;
      min-width: 300px;
    }

    @include for-md-only {
      font-size: 11px;
      min-width: 300px;
      //display: none;
    }
    @include for-phone-only {
      display: none;
    }
  }
  .footer_content {
    @include setContentWidth;
    margin: 2rem auto;
    padding-bottom: 4rem;
    border-bottom: 1px solid white;
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);

    @include for-xl-only {
      //grid-template-columns: 2.5fr repeat(4,1fr);
    }

    @include for-md-only {
      grid-template-columns: repeat(3, 1fr);
    }

    @include for-phone-only {
      //grid-template-columns: repeat(5,1fr);
      width: 100%;
      margin: 0rem auto;
      border-bottom: none;
      padding-bottom: 0rem;
    }

    .logo_cont {
      margin-bottom: 2rem;
      @include for-md-only {
        margin-bottom: 0;
      }
    }
    .button_section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include for-md-only {
        & > div {
          width: 33%;
        }
        flex-direction: row;
        grid-column-start: 1;
        grid-column-end: 7;
      }
    }
    .links_section {
      color: white;
      text-transform: uppercase;

      @include for-phone-only {
        //grid-column-start: 1;
        //grid-column-end: 7;
      }
      .link:first-child {
        font-weight: 700;
        font-size: 20px;
        line-height: 37px;

        @include for-xl-only {
          font-size: 16px;
        }
        @include for-phone-only {
          display: block;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .link {
        position: relative;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 37px;
        margin: 0.5rem auto;
        @include for-xl-only {
          font-size: 12px;
        }
        @include for-phone-only {
          //display: none;
          line-height: 18px;
          font-size: 10px;
        }

        .newTag {
          position: absolute;
          top: 0;
          left: 83px;
          color: #ffffff;
          background: linear-gradient(
            125.75deg,
            #ffa767 -8.85%,
            #eb468b 90.19%
          );
          border-radius: 100px;
          font-size: 9px;
          padding: 0 5px;
          font-weight: 500;
          text-transform: capitalize;
          line-height: 12px;
          // margin-top: -20px;

          @include for-phone-only {
            left: 70px;
            font-size: 6px;
            padding: 0 3px;
            top: -5px;
            line-height: 9px;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .social {
    display: flex;
    width: fit-content;
    margin: 1rem auto;

    @include for-phone-only {
      margin: 3rem auto 0;
    }

    img {
      width: 41px;
      aspect-ratio: 1;
      object-fit: cover;
      margin: 0 0.5rem;
      @include for-phone-only {
        width: 21px;
      }
    }
    img:nth-child(2) {
      width: 43px;
      @include for-phone-only {
        width: 22px;
      }
    }
  }
  .copy_right {
    text-align: center;
    font-weight: 500;
    color: #fff;
    font-size: 16px;
    line-height: 37px;
    letter-spacing: 2px;
    margin-bottom: 5px;

    @include for-phone-only {
      font-size: 10px;
      line-height: 15px;
      font-weight: 300;
      width: 100%;
      position: relative;
      top: 2rem;
    }
  }

  .small_text {
    @extend .copy_right;
    font-size: 11px;
    @include for-phone-only {
      font-size: 8px;
      margin-top: 5px;
    }
  }

  .policy_links {
    @extend .copy_right;
    @include justify-align-center;
    font-size: 12px;
    letter-spacing: 1px;
    flex-wrap: wrap;

    @include for-phone-only {
      font-size: 10px;
    }

    .link {
      cursor: pointer;

      span {
        margin: 0 0.5rem;
        text-decoration: none;
      }
    }

    .link:hover {
      text-decoration: underline;
    }

    .link:last-child {
      span {
        display: none;
      }
    }
  }
}
