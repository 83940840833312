@import "../../../../styles/mixins.scss";

.loginWrapper {
  @include align-column-center;
  color: var(--primary);
  .title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .desc {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 3.5rem;
  }
  .socialBtns {
    margin-top: 1.5rem;
    width: 100%;
    .socialBtn {
      @include justify-align-center;
      border: 1px solid rgba(196, 193, 200, 0.8);
      border-radius: 100px;
      width: 100%;
      height: 70px;
      font-size: 20px;
      font-weight: 500;
      margin: 0.8rem 0;
      cursor: pointer;
      img {
        margin-right: 1rem;
      }
    }
  }
}
