/* react custom select*/
.react-select-container {
  font-family: Arial;
  width: 100%;
  height: 40px;
}

.react-select-container .react-select__control {
  background-color: #431f4f;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

.react-select-container .react-select__value-container {
  color: rgba(255, 255, 255, 0.4);
  padding: 10px 16px;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  font-size: 11px;
  line-height: 13px;
}
.react-select-container .react-select__single-value {
  color: rgba(255, 255, 255, 0.4);
}
.react-select-container .react-select__indicators span {
  background-color: rgba(255, 255, 255, 0.4);
  display: none;
}
.react-select-container .react-select__dropdown-indicator {
  color: rgba(255, 255, 255, 0.4);
}

.react-select-container .react-select__menu {
  margin-top: 0.2em;
}
.react-select-container .react-select__menu-list {
  padding: 0;
}

/* Style items (options): */
.react-select-container .react-select__option {
  background-color: #431f4f;
  color: rgba(255, 255, 255, 0.4);
  padding: 13px 16px;
  border: none;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  font-size: 11px;
  line-height: 13px;
  overflow-y: auto;
}

.react-select-container .react-select__option:hover,
.react-select-container .react-select__option--is-selected:hover {
  opacity: 0.95 !important;
}
.react-select-container .react-select__option--is-selected {
  opacity: 0.95;
  background-color: #431f4f;
}

.react-select-container .react-select__option:active {
  background-color: #431f4f;
}

// react select transparent styles
.sellScreen-select {
  background-color: transparent !important;
  width: 3.5rem;
}

.sellScreen-brand-select {
  background-color: transparent !important;
  width: 6rem;
}

.sellScreen-select-lov-sub__control {
  background: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}


.sellScreen-select-lov-sub__indicator-separator {
  display: none;
}
.sellScreen-select-lov-sub__single-value {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 11px !important;

  color: #ffffff !important;
}
.sellScreen-select-lov-sub__value-container {
  padding: 0px !important;
  padding-left: 1px !important;
}

.sellScreen-select-lov-sub__indicator {
  padding: 0px !important;
}

// popup styles
.redeem-coins-popup-container {
  .icon-success,
  .icon-warning {
    height: 84px;
    width: 84px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .header {
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    color: #260e2e;
  }
  .sub-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #260e2e;
  }
  input[type='number'],
  input[type='text'] {
    display: flex;
    width: 100%;
    text-align: center;
    height: 46px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    margin-bottom: 25px;
    font-weight: bold;
  }
  .disclaimer {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    a {
      color: #4a90e2;
    }
  }
}
