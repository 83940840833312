@import "../../../../styles/mixins.scss";

.welcomeWrapper {
  color: var(--primary);
  position: relative;

  .lottieWrapper {
    position: absolute;
    top: -60px;
    width: 100%;
    height: 83%;
  }
  .innerWrapper {
    @include align-column-center;
    position: relative;
    z-index: 1;
    padding-top: 5rem;

    .title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0.5rem;
      margin-top: 4rem;
    }
    .desc {
      font-size: 18px;
      font-weight: 500;
      color: #815ef2;
      margin-bottom: 4rem;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
