@import "../../../../styles/mixins.scss";
@import "../../../../styles/media";

.popupCont {
  // background: rebeccapurple;
  display: flex;
  align-items: center;
  justify-content: center;

  .popupContentCont {
    width: 60%;
    height: 60%;
    // background: red;
  }

  h2 {
    font-size: 42px;
  }

  button {
    border-radius: 12px;
    border: 1px solid white;
    outline: none;
    color: #fff;
    margin: auto;
    padding: 4px 8px;
  }
  // --------->> updated
  .buy_pupup_container {
    @include justify-align-center();
    flex-direction: column;
    border-radius: 24px;
    background-color: #ffffff;
    padding: 37px;
    gap: 18px;

    .header {
      @include font-size-height-weight(20px, 24px, 700);
      color: #000000;
    }

    .description {
      @include font-size-height-weight(12px, 18px, 400);
      color: #000000;
      opacity: 50%;
    }

    .wallet_diamonds_count {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8.02px 18.72px;
      gap: 8px;
      border-radius: 53px;
      background: rgba(138, 92, 236, 0.16);
    }
    .diamond_plans_section {
      max-width: 440px;
      display: flex;
      flex-wrap: wrap;
      gap: 10.5px;
      .plan_card {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 21.39px;
        background: linear-gradient(145.78deg, #b18bfd 13.8%, #6846eb 93.2%);
        border-radius: 10px;
        gap: 13px;
        cursor: pointer;

        .price_section {
          display: flex;
          flex-direction: column;
          gap: 10px;
          color: #ffffff;
          .diamond_count {
            @include font-size-height-weight(26px, 17px, 600);
          }
          .price_container {
            @include font-size-height-weight(16px, 17px, 500);
            display: flex;
            gap: 8px;
            .cost_price {
              color: #e7cfee;
            }
            .sale_price {
              color: #ffffff;
            }
          }
        }
      }
    }

    .close_buy_popup {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    @include for-phone-only {
      border-radius: 24px 24px 0px 0px;
      position: fixed;
      bottom: 0px;
      left: 0px;
      padding: 21px;
      max-height: 90vh;
      .diamond_plans_section {
        overflow: scroll;
        .plan_card {
          padding: 16px;
          gap: 2px;
          .diamong_icon {
            height: 32px;
          }
          .price_section {
            gap: 5px;
            .diamond_count {
              font-size: 17px;
            }
            .price_container {
              font-size: 12px;
              gap: 4px;
            }
          }
        }
      }
    }
  }
}
