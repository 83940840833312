@import "../styles.module";
@import "../../../styles/mixins.scss";
.logo_cont {
  display: flex;
  align-items: center;
  z-index: 4;
  cursor: pointer;

  img {
    width: 81px;
    aspect-ratio: 1;
    object-fit: contain;
    margin-right: 15px;
    @include for-xl-only {
      width: 60px;
    }

    @include for-md-only {
      width: 50px;
    }

    @include for-phone-only {
      width: 42px;
      margin-right: 5px;
    }
  }

  p {
    font-size: 39px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 2px;
    @include for-xl-only {
      font-size: 29px;
      line-height: 27px;
      letter-spacing: 1.5px;
    }
    @include for-md-only {
      font-size: 22px;
      letter-spacing: 1.5px;
    }
    @include for-phone-only {
      font-size: 16px;
      letter-spacing: 1.5px;
    }
  }
}
.header_cont {
  @include setContentWidth;
  height: 81px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 1);

  .wallet_diamonds_count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8.02px 18.72px;
    height: 58px;
    border-radius: 53px;
    background: rgba(138, 92, 236, 0.16);
    margin-right: 10px;
    cursor: pointer;
    img {
      height: 18px;
      margin-right: 3px;
    }
    .count {
      @include font-size-height-weight(16px, 20px, 500);
      color: #f45698;
      @include for-phone-only {
        @include font-size-height-weight(10px, 20px, 400);
      }
    }

    @include for-xl-only {
      height: 45px;
      border-radius: 30px;
      font-size: 14px;
      letter-spacing: 1.5px;
    }

    @include for-phone-only {
      height: 37px;
      font-size: 12px;
      padding: 0px 13px;
      img {
        margin-right: 1px;
        height: 12px;
      }
    }
  }

  .mobile_header {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 0;
    height: 0;
    background: rgba(112, 101, 255, 0.8);
    display: block;
    z-index: 3;
    overflow: hidden;

    .links_cont {
      @include setContentWidth;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .link:first-child {
        margin-top: 100px;
      }
      .link {
        position: relative;
        opacity: 0;
        color: white;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin-top: 1rem;
        margin-left: 4px;
      }
    }
  }
  @keyframes headerContAnimation {
    from {
      width: 100vw;
      margin-left: 100vw;
      height: 20px;
      top: 0;
      right: 0;
    }
    25% {
      width: 100vw;
      margin-left: 0;
    }
    to {
      width: 100vw;
      height: 100vh;
      right: 0;
      top: 0;
    }
  }
  @keyframes linksAnimation {
    from {
      opacity: 0;
      top: -15px;
    }
    50% {
      opacity: 0;
      top: -55px;
    }
    90% {
      top: 5px;
    }
    to {
      opacity: 1;
      top: 0;
    }
  }

  .show_mobile_header {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    left: 0;
    top: 0;
    animation: headerContAnimation 300ms ease-in;

    .links_cont {
      .link {
        opacity: 1;
        animation: linksAnimation 600ms ease-in;
      }
    }
  }

  @include for-phone-only {
    // justify-content: normal;
  }
  .links_cont {
    flex: 1;
    margin-left: 5rem;
    display: flex;
    align-items: center;
    @include for-md-only {
      margin: 0 3rem;
      justify-content: space-between;
    }
    @include for-phone-only {
      // display: none;
    }
    & > div {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      margin-right: 2rem;

      @include for-xl-only {
        font-size: 12px;
        line-height: 37px;
      }

      @include for-md-only {
        margin-right: 1rem;
      }
    }
  }

  .login_btn {
    font-weight: 600;
    letter-spacing: 2px;
    font-family: "Poppins", sans-serif;
    color: white;
    width: 149px;
    outline: none;
    background: linear-gradient(125.75deg, #ffa767 -8.85%, #eb468b 90.19%);
    height: 58px;
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 40px;

    @include for-xl-only {
      width: 120px;
      height: 45px;
      border-radius: 30px;
      border: 1.5px solid rgba(255, 255, 255, 1);
      font-size: 14px;
      letter-spacing: 1.5px;
    }

    @include for-phone-only {
      height: 37px;
      width: 100px;
      font-size: 10px;
      margin-left: auto;
      margin-right: 5px;
      letter-spacing: 0.5px;
    }
  }

  .menu_icon_cont {
    display: none;
    z-index: 4;
    @include for-phone-only {
      display: none;
    }

    .menu_icon {
      width: 37px;
    }
  }
}
