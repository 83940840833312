@import "../../../../styles/mixins.scss";
@import "../../../../styles/media";

.ageSelectionWrapper {
  @include align-column-center;
  color: var(--primary);

  .error {
    font-size: 12px;
    font-weight: 400;
    margin-top: 1rem;
  }

  .button {
    background: var(--primaryBackground3);
    width: 100%;
    height: 70px;
    font-size: 20px;
    margin-top: 3rem;

    &:disabled {
      background: #c4c1c8;
    }
  }
}
