@import "../../../styles/mixins.scss";
@import "../../../styles/media";

.toastContainer {
  height: 64px;
  width: 312px;
  background-color: #ffff;
  color: #757575;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: -322px;
  top: 88%;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 2;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  font-family: sans-serif;
  z-index: 10;
  @include for-phone-only {
    top: 100%;
    left: 0px;
    bottom: 0px;
    margin: 0 calc(calc(100vw - 80%) / 2);
  }

  .toast_content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .close_icon {
      position: absolute;
      top: 9px;
      right: 9px;
      cursor: pointer;
    }
    span {
      font-size: 15px;
    }
  }

  &.showToast {
    transform: translate(348px, 0px);
    @include for-phone-only {
      transform: translate(0px, 0px);
      top: 84%;
    }
  }

  // &.success {
  //   background: #07bc0c;
  // }

  // &.error {
  //   background: #e74c3c;
  // }

  // &.warning {
  //   background: #f1c40f;
  // }
}
