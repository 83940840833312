@import "../../../../styles/mixins.scss";

.picSelectionWrapper {
  @include align-column-center;
  color: var(--primary);

  .inputWrapper {
    margin-top: 2.5rem;
    margin-bottom: 4rem;
    position: relative;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

    .imgWrapper {
      @include justify-align-center;
      width: 171px;
      height: 171px;
      cursor: pointer;
      background: linear-gradient(
        135deg,
        #7ed8db 2.91%,
        #81a9e7 33.33%,
        #8298e5 54.82%,
        #ed9ad0 100%
      );
      border-radius: 2rem;
      position: relative;

      .plus {
        position: absolute;
        right: -15px;
        bottom: -15px;
        background: linear-gradient(41deg, #ff6132 1.02%, #ffb872 86.11%);
        border-radius: 1rem;
        width: 44px;
        height: 44px;
        @include justify-align-center;
      }

      .profilePic {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }
  }

  .skip {
    color: #815ef2;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2rem;
    cursor: pointer;
  }

  .button {
    background: var(--primaryBackground3);
    width: 100%;
    height: 70px;
    font-size: 20px;
    margin-top: 3rem;

    &:disabled {
      background: #c4c1c8;
    }
  }
}
