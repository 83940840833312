@import "../../../../styles/mixins.scss";

.otpWrapper {
  color: var(--primary);
  @include align-column-center;

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 3rem;
    text-align: center;
    .expire {
      opacity: 0.7;
      margin-bottom: 6px;
      margin-top: 3rem;
    }
    .notReceived {
      opacity: 0.8;
      span {
        margin-left: 0.5rem;
        color: #815ef2;
        cursor: pointer;
        &.resend_otp_disabled {
          color: #6d6d6d;
        }
      }
    }
    .error {
      color: red;
      font-size: 14px;
      margin: 0.5rem;
    }
  }
  .button {
    background: var(--primaryBackground3);
    width: 100%;
    height: 70px;
    font-size: 20px;
  }
}
