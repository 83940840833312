@import "../../../../styles/mixins.scss";

.nameInputWrapper {
  @include align-column-center;
  color: var(--primary);

  .title {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 4rem;
  }
  .input {
    color: var(--primary);
    border-bottom: 1px solid #c4c1c8;
    width: 80%;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.5rem;
    margin-bottom: 4rem;
    background-color: transparent;
    outline: none;
    &::placeholder {
      opacity: 0.4;
    }
  }
  .button {
    background: var(--primaryBackground3);
    width: 100%;
    height: 70px;
    font-size: 20px;

    &:disabled {
      background: #c4c1c8;
    }
  }
}
