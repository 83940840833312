
.main-header{
    height: 60px;
    color: #fff;
}
.solid{
    background: #201024;
}

.main-header-back-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    padding: 22px 24px;
}
.main-header-back{
    line-height: 60px;
    width: 14px;
    height: 14px;
}
.main-header-content{
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    color: #fff;
}
.main-header-content:hover {
    color: inherit !important;
    text-decoration: none !important;
}
.main-header .icon-fire{
    width: 22px;
    height: 26px;
    padding-left: 8px;
    margin-right: 4px;
}

.sticky-header{
    position: sticky;
    top: 0%;
    z-index: 50;
}

.center-content {
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
