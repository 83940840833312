.otpGroup {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otpInput {
  width: 100%;
  height: 70px;
  margin: 10px 0px;
  border: none;
  border-bottom: 1.3px solid #815ef2;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  background-color: #fff;
  color: var(--primary);

  &:focus-visible {
    outline: none;
  }
}
