@import "../../../styles/media";
@import "../../../styles/mixins.scss";

.popupWrapper {
  .loginFlowWrapper {
    background-color: var(--terinary);
    width: 500px;
    padding: 1.5rem;
    border-radius: 2.5rem;
    max-height: 90vh;
    overflow-y: scroll;

    .topIcons {
      @include justify-spaceBetween;
    }

    .icon {
      border: 1px solid rgba(0, 0, 0, 0.08);
      position: relative;
      border-radius: 100px;
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      @include justify-align-center;
      z-index: 2;
    }
  }

  @include for-phone-only {
    justify-content: flex-end !important;
    .loginFlowWrapper {
      width: 100vw;
      border-radius: 2.5rem 2.5rem 0 0;
      max-height: 80vh;
      overflow: scroll;
      padding-bottom: 6rem;
    }
  }
}
