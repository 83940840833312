@import "../../../styles//mixins.scss";

.iosPicker {
  display: flex;
  width: 300px;
  box-shadow: 0 0.5vw 2vw -5px rgba(black, 0.2);
  border-radius: 15px;
  position: relative;
  background: linear-gradient(146deg, #b18bfd 13.8%, #6846eb 93.2%);
  font-size: 22px;
  position: relative;
  font-feature-settings: "tnum";
  font-weight: 400;
  margin-top: 2rem;
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 8rem;
    pointer-events: none;
  }
  &:before {
    top: 0;
    background: linear-gradient(rgba(white, 0.2), rgba(white, 0.4));
  }
  &:after {
    bottom: 0;
    height: 8rem;
    background: linear-gradient(rgba(white, 0.4), rgba(white, 0.2));
  }

  ul {
    @include hideScrollbars();
    max-height: 15rem;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    padding: 10rem 0;
    flex: 1;
  }
  li {
    scroll-snap-align: center;
    height: 65px;
    text-align: center;
    word-spacing: 0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);

    &.selected {
      color: #fff;
      font-weight: 600;
    }
  }
}

.picker-day {
  padding-right: 0.5em;
  span {
    padding-left: 0.56em;
  }
}
