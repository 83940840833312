.icon{
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

/* start old icons */
.icon-line-search{
    background: url(./search.svg);
}
/* end old icons */
.left-arrow{
    background: url(./arrow-left.svg);
}
.icon-caret-down{
    background: url(./caret-down.svg);
}
.icon-fire{
    background: url(./fire.svg);
}
.icon-sw{
    background: url(./sw.svg);
}
.icon-coin-l1{
    background: url(./coin-l1.svg);
}
.icon-coin-l2{
    background: url(./coin-l2.svg);
}
.icon-coin-r1{
    background: url(./coin-r1.svg);
}
.icon-coin-r2{
    background: url(./coin-r2.svg);
}
.icon-big-coin{
    background: url(./big-coin.png);
    /* background: url(./big-coin.svg); */
}
.icon-coin-stack{
    background: url(./coins-stack.svg);
}
.icon-download{
    background: url(./download.svg);
}
.icon-lock{
    background: url(./lock.svg);
}

.icon-main-logo{
    /* background: url(./icon-main-logo.svg); */
    background: url(./icon-secondary-logo.jpg);
}
.icon-medal{
    background: url(./icon-medal.svg);
}
.icon-graph{
    background: url(./icon-graph.svg);
}

.icon-leaderboard{
    background: url(./icon-leaderboard.svg);
}

.icon-chingari{
    background: url(./icon-chingari.svg);
}

.icon-upload{
    background: url(./icon-upload.svg);
}

.icon-stroke-upload{
    background: url(./icon-stroke-upload.svg);
}

.icon-close{
    background: url(./icon-close.svg);
}

.icon-warning{
    background: url(./icon-warning.svg);
}


.icon-success {
    background: url(./icon-success.svg);
}

.icon-up-arrow{
    background: url(./icon-up-arrow.svg);
}
.icon-star{
    background: url(./star.svg);
}
.icon-gift{
    background: url(./gift.svg);
}
.icon-rupees{
    background: url(./rupees.svg);
}
.icon-rupees-chingari{
    background: url(./rupees-chingari.svg);
}
.icon-wallet-coin{
    background: url(./wallet-coin.svg);
}
.icon-wallet-diamond{
    background: url(./wallet-diamond.svg);
}

.icon-cash-card-rupees{
    background: url(./cash-card-rupees.svg);
}

.icon-coin-card-graphics{
    background: url(./coin-card-graphics.svg);
}
.icon-diamond-card-graphics{
    background: url(./diamond-card-graphics.svg);
}

.icon-cash-card-graphics{
    background: url(./cash-card-graphics.svg);
}

.icon-reward-card-graphics{
    background: url(./reward-card-graphics.svg);
}

.icon-gift-card-graphics{
    background: url(./icon-gift-card-graphics.svg);
}


.icon-wallet-graphics{
    background: url(./wallet-graphics.png);
    /* background: url(./wallet-graphics.svg); */
}

.icon-scissors{
    background: url(./icon-scissors.svg);
}

.icon-color-insta{
    background: url(./icon-color-insta.svg);
}

.icon-color-whatsapp{
    background: url(./icon-color-whatsapp.svg);
}

.icon-color-fb{
    background: url(./icon-color-fb.svg);
}

.icon-write{
    background: url(./icon-write.svg);
}

.icon-write-black{
    background: url(./icon-write-black.svg);
}

.icon-camera{
    background: url(./icon-camera.svg);
}

.icon-info{
    background: url(./icon-info.svg);
}

.icon-mic{
    background: url(./mic.svg);
}

.icon-flame{
    background: url(./flame.svg);
}

.icon-message-line{
    background: url(./message-line.svg);
}


.icon-video{
    background: url(./video.svg);
}

.icon-fire-white{
    background: url(./white-fire.svg);
}

.icon-gallery{
    background: url(./gallery.svg);
}

.icon-tick-line{
    background: url(./tick.svg);
}

.icon-tick-green-line{
    background: url(./tick-green.svg);
}

.icon-mute-emoji{
    background: url(./mute-emoji.svg);
}

.icon-pinned{
    background: url(./pinned.svg);
}

.icon-mute{
    background: url(./mute.svg);
}

.icon-headphones{
    background: url(./headphones.svg);
}