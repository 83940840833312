@import "../../../styles/mixins.scss";
@import "../../../styles/media";

.popupContainer {
  background: linear-gradient(
    129.84deg,
    rgba(0, 0, 0, 0.6) -1.69%,
    rgba(0, 0, 0, 0.6) 103.1%
  );
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: none;
  top: 0;
  left: 0;

  &.show {
    @include justify-align-center;
    flex-direction: column;
  }

  .popupInner {
    position: relative;
  }

  &.reviewPopup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(45deg, #ffffff, #fff6fb, #f5f1ff);
    @include for-phone-only {
      // width: 100vw;
      // height: 110vh;
    }
  }
}
