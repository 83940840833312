@import "../../../styles/media";

.CallingPopupWraper {
  background: linear-gradient(-80deg, #7166f9, #9686fc, #84c4ff);
  position: fixed;
  top: 40%;
  right: 0px;
  height: 70px;
  border-radius: 50px 0px 0px 50px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;

  @include for-phone-only {
    top: 30%;
    right: 0px;
  }

  img {
    border: 1.07px solid #ffffff;
    border-radius: 50%;
    height: 46px;
    width: 46px;
  }
  .lottie {
    margin-left: 7.5px;
    img {
      border: none;
      height: 35px;
      margin-right: 8px;
    }
  }
  button {
    height: 46px;
    width: 46px;
    border-radius: 50%;
    background: linear-gradient(#ff5e5e, #db2f81);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8.9px;
    cursor: pointer;
    img {
      border: none;
    }
  }
}
