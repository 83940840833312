.title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
}
.description {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 0 14px;
}
