// profile details
.profile-details{
  background-color: #1f0927;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 48px 8px;

  @include for-phone-only {    
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  @include for-desktop-up {    
    background-color: #391542;
    padding:31px 0 22px;
  }

  .profile-first-space{
    @include for-phone-only {  
      margin-bottom: 24px;  
    }
  }

  .profile-btn{
    display: none;
    @include for-desktop-up {   
      display: flex;
    }
  }

  .m-profile-btn{
    display: flex;
    justify-content: flex-start!important;
    @include for-desktop-up {   
      display: none;
    }
  }

  .profile-container{
    max-width:900px;
    margin: 0 auto;
  }

  .datacount-container{
    @include for-phone-only {  
      margin-right: 0!important;
      flex: 1 1;
    }
  }

  .m-profile-status{
    display: flex;
    padding: 10px 15px 30px 15px;
    word-break: break-all;
    @include for-desktop-up {    
      display: none;
    }
  }

  .data-count{
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 6px;
    text-transform: capitalize;
    @include for-phone-only {  
      font-size: 16px;
      line-height: 23px;
    }
  }

  .data-label{
    font-size: 14px;
    line-height: 16px;
    opacity: 0.3;
    text-transform: capitalize;
    @include for-phone-only {  
      line-height: 24px;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      opacity: 1;
    }
  }

  .separator{
    @include for-desktop-up {    
      padding-bottom: 36px;
      margin-bottom: 27px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  .btn{
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    padding: 6px 24px;
    border: 1px solid transparent;
    text-align: center;
    outline: 0;
    @include for-desktop-up {    
      padding: 7px 24px;
      line-height: 16px;
    }
  }
  .follow{
    background-color: #ff4843;
  }
  .share{
    background-color: #501c54;
    border-color: #501c54;
    @include for-phone-only {   
      border: 1px solid #ff4843;
      color: #ff4843;
      background-color: transparent;
      margin-left: 8px!important;
    }
  }
}

.profile-identity{
  @include for-desktop-up {   
    margin-left: 30px;
  }

  .sharename{
    display: none;
    @include for-phone-only { 
      display: flex;  
      margin-bottom: 8px;
      font-weight: 400;
      line-height: 21px;
      color: #ad80bc;
      font-size: 14px;
    }
  }

  .username{
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    margin-bottom: 4px;
    @include for-desktop-up {  
      font-size: 20px;
      margin-bottom: 16px;
    }
  }

  .description{
    font-size: 16px;
    max-width: 42rem;
    word-break: break-all;
    opacity: 0.3;
    display: none;
    @include for-desktop-up {   
      display: flex;
    }
  }
}

.profile-user-image{
  height: 83px;
  width: 83px;
  margin: 0 15px 0;
  @include for-desktop-up {   
    height: 60px;
    width: 60px;
    margin: 0;
  }
}
.profile-avatar{
  border-radius: 50%;
  border:1.5px solid #fff;
}

.user-verified-premium{
  height: 24px;
  width: 24px;
}

body{
  background-color: var(--contest-background-color);
  @include for-desktop-up {    
    background-color: #260e2e;
  }
}

  .feed-content{
    margin: 0 auto;
    max-width: 902px;
    display: grid;
    grid-template-columns: repeat(3,33.33%);
    position: relative;
    padding: 24px 0;

    @include for-desktop-up {    
      grid-template-columns: repeat(3,32%);
      grid-column-gap: 2%;
      grid-row-gap: 19px;
      padding: 35px 0;
    }

    .feed{
      height: 100%;
      width: 100%;
      position: relative;
    }
  }

  .content-card{
    height: 200px;
    @include for-desktop-up {    
      border-radius: 13px;
      overflow: hidden;
      height: 453px;
    }

    .image-block{
      height: 200px;
      width: 100%;
      @include for-desktop-up {    
        height: 453px;
      }
    }
    .chingari-icon{
      width: 19px;
      height: 22px;
    }
  }

  .content-likes{
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg,hsla(0,0%,76.9%,0) -25.4%,rgba(0,0,0,.43) 70.17%);
    .likes-container{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 33px 14px 19px;
      background: linear-gradient(0deg,rgba(0,0,0,.55) 44.54%,transparent 94.83%);
      @include for-desktop-up {    
        padding: 71px 22px 15px;
      }
    }
    .likes-text{
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      @include for-desktop-up {    
        font-size: 14px;
        line-height: 16px;
      }
    }
  }


.loading-skeleton {
  overflow: hidden;
  .image{
    height: 83px!important;
    width: 83px!important;
    @include for-desktop-up {  
      height: 60px!important;
      width: 60px!important;  
    }
  }
}

.border-radius{
  @include for-desktop-up {
    border-radius: 0.5rem!important;
  }
}

.ssc-token{
  margin-right: 20px!important;
  @include for-desktop-up {
    margin-right: 40px!important;  
  }
}

.only-desktop{
  display: initial;
  @include for-phone-only { 
    display: none;
  }
}
