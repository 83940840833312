@import "../../../../styles/mixins.scss";

.genderWrapper {
  @include align-column-center;
  color: var(--primary);

  .genderList {
    margin-top: 1rem;
    width: 100%;
    .genderItem {
      cursor: pointer;
      background: #efe9ff;
      height: 90px;
      border-radius: 20px;
      width: 100%;
      padding: 0 2rem;
      margin: 2rem 0;
      @include align-center-justify-spaceBetween;

      &.selected {
        background: linear-gradient(146deg, #b18bfd 13.8%, #6846eb 93.2%);
        color: #fff;
      }

      .image {
        margin-top: -1.1rem;
      }
    }
  }

  .button {
    background: var(--primaryBackground3);
    width: 100%;
    height: 70px;
    font-size: 20px;

    &:disabled {
      background: #c4c1c8;
    }
  }
}
