@import "../../../../styles/mixins.scss";

.mobileWrapper {
  color: var(--primary);
  @include align-column-center;

  .phoneInputWrapper {
    width: 100%;
    margin: 3.5rem 0;
    label {
      display: block;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }
    .phoneContainer {
      // background-color: red;
    }
    .phoneInput {
      color: var(--primary);
      border: 1.3px solid rgba(138, 92, 236, 0.6);
      height: 3rem;
      width: 100%;
      border-radius: 13px;
    }
    .dropdown {
      background-color: transparent;
      border: none;
    }
    .error {
      color: red;
      font-size: 10px;
      margin: 0.5rem;
    }
  }

  .btn {
    background: var(--primaryBackground3);
    width: 100%;
    height: 70px;
    font-size: 20px;
  }
}
