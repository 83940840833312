@import "../../../styles/mixins.scss";
@import "../../../styles/media";
.popupWraper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  bottom: 0px;
  @include justify-align-center;
  @include for-phone-only {
    height: 100vh;
    width: 100vw;
  }
}

.popupContentWrapper {
  // background-color: greenyellow;
  color: var(--primary);
  height: 90vh;
  padding-top: 50px;
  overflow: scroll;
  @include for-phone-only {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    padding: 100px 15px;
  }
  width: 415px;

  .successWrapper {
    width: 100%;
    height: 100%;
    @include justify-align-center;
    flex-direction: column;

    .right_image_wraper {
      position: absolute;
      top: 37vh;
      height: 75px;
      width: 75px;
      border-radius: 50px;
      background: linear-gradient(
        275.76deg,
        #2072ee 9.59%,
        #4ee4b7 94.18%,
        #36efb7 94.18%
      );
      @include justify-align-center;
    }
    .text {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 28px;
    }
    .button {
      background: var(--primaryBackground3);
      width: 50%;
    }
  }

  .headerContainer {
    width: 100%;
    height: 120.9px;
    background-color: #ffffff;
    border-radius: 17.7px;
    @include justify-align-center;
    flex-direction: column;
    box-shadow: 0px 2.530487298965454px 15.182924270629883px 0px #3e1a5024;
    gap: 12.66px;
    margin-bottom: 23px;

    .userImg {
      margin-top: -12%;
      border-radius: 100px;
    }
    .headerText {
      @include justify-align-center;
      flex-direction: column;
      p {
        color: #2c2334;
        font-size: 20.24px;
        line-height: 24.29px;
        font-weight: 600;
        letter-spacing: 1px;
      }
      span {
        color: #2c2334;
        font-size: 12px;
        line-height: 15.87px;
        opacity: 80%;
        font-weight: 400;
        letter-spacing: 0.7px;
      }
    }

    // @include for-phone-only {
    //   display: none;
    // }
  }
  .bodyContainer {
    width: 100%;
    // height: 436.5px;
    background-color: #ffffff;
    border-radius: 17.7px;
    @include justify-align-center;
    flex-direction: column;
    box-shadow: 0px 2.530487298965454px 15.182924270629883px 0px #3e1a5024;
    padding: 25px;
    margin-bottom: 25px;
    @include for-phone-only {
      padding: 25px 10px;
    }

    .bodyContentWrapper {
      @include justify-align-center;
      flex-direction: column;
      gap: 12px;
      span {
        color: #2c2334;
        font-size: 15.18px;
        line-height: 22.77px;
        font-weight: 500;
      }
      .starsContainer {
        @include justify-align-center;
        gap: 13px;
        .starIcon {
          cursor: pointer;
        }
      }

      .ReviewOptionContainer {
        @include justify-align-center;
        flex-wrap: wrap;
        gap: 12.65px;
        // min-width: 362px;
        // overflow: hidden;
        .optionButton {
          height: 53.37px;
          width: 174.6px;
          color: #2c2334;
          background-color: rgba(196, 193, 200, 0.24);
          font-size: 15.18px;
          line-height: 22.77px;
          border: none;
          border-radius: 10.12px;
          cursor: pointer;
          @include for-phone-only {
            width: 145px;
          }

          &.selectedButton {
            // padding: 15.18px 12.65px;
            background: linear-gradient(#b18bfd, #6846eb);
            box-shadow: 0px 5.060977458953857px 5.060977458953857px 0px
              #b181e724;
            color: #ffffff;
          }
        }
      }
      .messageInput {
        height: 78.65px;
        width: 100%;
        background-color: transparent;
        border-radius: 12.65px;
        resize: none;
        outline: none;
        padding: 15px;
        color: #2c2334;
        border: 1.2px solid #815ef2;
      }
    }
  }

  // submit Button Class
  .submitButton {
    color: #ffffff;
    background: var(--primaryBackground3);
    border: none;
    border-radius: 38.09px;
    height: 60px;
    width: 100%;
    font-size: 16.04px;
    line-height: 22.05px;
    font-weight: 600;
    cursor: pointer;
    @include for-phone-only {
      margin-bottom: 20px;
    }

    &.isDisabled {
      background: #665977e3;
    }
  }
}

.backIcon {
  position: absolute;
  top: 0px;
  left: -155px;
  cursor: pointer;
  @include for-phone-only {
    top: 48px;
    left: 8px;
  }
}
