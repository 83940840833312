@import "./index";
@import "MobileHeader";
@import "custom";
@import "../images/icons/icons.css";
.app-desktop {
  background-color: #260e2e;
}

.profile-image {
  width: 3.75rem;
  height: 3.75rem;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 50%);
  box-shadow: 0 0 2px rgb(0 0 0 / 50%);
}

:root {
  --primary: #2c2334;
  --secondary: "#2C2334";
  --terinary: "#ffffff";
  --primaryBackground1: linear-gradient(
    93deg,
    #1a68ff -25.16%,
    #8031eb 46.74%,
    #f59dc2 111.52%
  );
  --primaryBackground2: linear-gradient(
    180deg,
    rgba(14, 10, 19, 0.65) 0%,
    rgba(14, 10, 19, 0.79) 13.89%,
    rgba(14, 10, 19, 0.9) 29.89%,
    rgba(14, 10, 19, 0.96) 45.31%,
    #0e0a13 69.29%,
    #0e0a13 69.29%
  );
  --primaryBackground3: linear-gradient(
    99deg,
    #7166f9 0%,
    #9686fc 51.61%,
    #84c4ff 102.15%
  );

  --primaryBackground4: #ffffff;

  --primaryBackgroundContainer: linear-gradient(
    165.77deg,
    #8a5bed -9.28%,
    #5739cd 106.89%
  );
}
