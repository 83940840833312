@import "../../../styles/mixins.scss";
@import "../../../styles/media";

.logoutPopupWrapper {
  color: var(--primary);
  background-color: #ffffff;
  width: 500px;
  border-radius: 20px;
  padding: 40px;
  margin: auto;
  @include justify-align-center;
  flex-direction: column;
  text-align: center;

  @include for-phone-only {
    width: 80vw;
    padding: 30px 20px;
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    @include for-phone-only {
      font-size: 1rem;
    }
  }
  .desc {
    font-size: 14px;
    font-family: 500;
    @include for-phone-only {
      font-size: 0.8rem;
    }
  }

  .buttons {
    margin-top: 40px;
    display: flex;
    gap: 1rem;
    align-self: stretch;

    @include for-phone-only {
      margin-top: 20px;
    }

    .button {
      flex: 1;
      height: 70px;
      background: var(--primaryBackground3);
      border-radius: 100px;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      cursor: pointer;
      &:first-child {
        color: #815ef2;
        background: none;
        border-image: linear-gradient(#b18bfd, #6846eb);
        border: 1px solid;
      }
      @include justify-align-center;

      @include for-phone-only {
        font-size: 12px;
        height: 2rem;
      }
    }
  }
}
