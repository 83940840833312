@import "../../../../styles/mixins.scss";

.languageWrapper {
  @include align-column-center;
  color: var(--primary);

  .langSelectWrapper {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    .langItem {
      width: 136px;
      background: #f6f1fe;
      margin: 0.37rem;
      padding: 1rem;
      border-radius: 10px;
      cursor: pointer;
      @include align-center-justify-spaceBetween;

      &.selected {
        color: #ffffff;
        background: linear-gradient(146deg, #b18bfd 13.8%, #6846eb 93.2%);
      }

      .langName {
        font-size: 14px;
        font-weight: 400;
      }
      .name {
        font-size: 8px;
        font-weight: 500;
      }

      .radio {
        width: 1rem;
        height: 1rem;
        border: 1px solid var(--primary);
        border-radius: 100px;
      }
    }
  }

  .button {
    background: var(--primaryBackground3);
    width: 100%;
    height: 70px;
    font-size: 20px;
    margin-top: 3rem;

    &:disabled {
      background: #c4c1c8;
    }
  }
}
