@import "../../../../styles/mixins.scss";
@import "../../../../styles/media";
.IB_popup_container {
  background-color: #ffffff;
  border-radius: 24px;
  padding: 25px 45px;
  @include justify-align-center;
  flex-direction: column;

  .IB_popup_text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 25px 0px;
    .IB_header {
      color: #000000;
      @include font-size-height-weight(20px, 30px, 600);
    }
    .IB_discription {
      color: #2c2334;
      @include font-size-height-weight(12px, 18px, 400);
    }
  }

  .btn_top_up {
    background: linear-gradient(
      99.24deg,
      #7166f9 0%,
      #9686fc 51.61%,
      #84c4ff 102.15%
    );
    padding: 13px 50px;
    @include font-size-height-weight(16px, 24px, 600);
    color: #ffffff;
    border-radius: 32px;
  }

  .close_popup {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
}
